import getCountries from './countries';

export const getCountryDetails = (newCountry, existingCountry) => {
  const currentCountry = existingCountry ? existingCountry : newCountry;

  const counties = getCountries();
  const countryRegistered = counties.find(
    country => country?.countryCode === currentCountry,
  );

  return countryRegistered
    ? countryRegistered
    : {
        countryCallingCode: null,
        name: null,
        flag: null,
        countryCode: null,
      };
};
