import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { useHistory } from 'react-router-dom';
import * as recurringPaymentDataState from '../_redux/recurringPaymentRedux';
import PayWithBankTransfer from '../components/BankDetails';
import { setUpRecurringPayments } from '../_redux/recurringPaymentAction';
import Loader from '../../Common/Loader';
import ErrorModal from '../../Common/ErrorModal';

const BankRecurringPaymentConfirmation = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleStepChange, id: stepperId } = props;
  const recurringPaymentData = useSelector(state => state.recurringPayments);
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const { currency } = recurringPaymentData;

  const handlePrevious = async () => {
    return history.push('/recurring-payments');
  };

  useEffect(() => {
    handleStepChange({ next: stepperId });
  }, []);

  const handleNext = async () => {
    try {
      dispatch(
        recurringPaymentDataState.actions.updateRecurringPaymentData({
          ...recurringPaymentData,
          currency: currency,
        }),
      );
      const {
        years,
        planCurrency,
        planName,
        months,
        planSubscriptionId,
        planId,
        ...rest
      } = recurringPaymentData;
      setLoading(true);

      await setUpRecurringPayments({
        ...rest,
        amount: parseFloat(recurringPaymentData.amount),
        currency: currency,
        planSubscriptionId,
        ...rest,
      });
      setLoading(false);
      return history.push(
        `/portfolio/viewInvestment/${recurringPaymentData?.planSubscriptionId}`,
      );
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  return (
    <div className="mt-10">
      <>
        {loading ? (
          <Loader />
        ) : (
          <form className="px-md-20 px-3">
            <KycPageContentWrapper>
              <PayWithBankTransfer currency={currency} />
              <ButtonsContainer justifyContent="justify-content-between">
                <div className="w-100">
                  <ButtonsContainer justifyContent="justify-content-between">
                    <CancelButton
                      buttonText="Previous"
                      handleOnClick={() => handlePrevious()}
                      twinButton
                    />

                    <ConfirmButton
                      buttonText={'Confirm'}
                      handleOnClick={handleNext}
                      twinButton
                    />
                  </ButtonsContainer>
                </div>
              </ButtonsContainer>
              {recurringPaymentData?.planCurrency !== currency && (
                <div className="text-left mt-5">
                  <strong>Note: </strong>
                  Please note that as your bank account currency does not match
                  the fund currency, Ndovu will use up-to-date currency
                  conversion rates for your transactions.
                </div>
              )}
            </KycPageContentWrapper>
          </form>
        )}

        <ErrorModal
          backgroundColor="bg-orange-100"
          open={alertOn}
          content={<>{alert?.alertMessage}</>}
          setOpen={setAlertOn}
        />
      </>
    </div>
  );
};

export default BankRecurringPaymentConfirmation;
