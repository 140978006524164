import axios from 'axios';
import dotenv from 'dotenv';
dotenv.config();
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SET_UP_RECURRING_PAYMENTS = `${REACT_APP_BASE_URL}/v1/investor/recurring-payments`;
const GET_INVESTOR_RECURRING_PAYMENTS = `${REACT_APP_BASE_URL}/v1/investor/recurring-payments`;

const setUpRecurringPayments = async data => {
  const res = await axios.post(SET_UP_RECURRING_PAYMENTS, data);
  return res;
};

const getInvestorRecurringPayments = async id => {
  const res = await axios.get(`${GET_INVESTOR_RECURRING_PAYMENTS}/${id}`);
  return res;
};

const updateInvestorRecurringPaymentStatus = async (id, data) => {
  const res = await axios.put(
    `${GET_INVESTOR_RECURRING_PAYMENTS}/${id}/status`,
    data,
  );
  return res;
};
export {
  setUpRecurringPayments,
  getInvestorRecurringPayments,
  updateInvestorRecurringPaymentStatus,
};
