import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BankDetails from '../../Invest/components/PayWithBankTransfer/bankDetails';
import { findCurrency } from '../../../helpers/findCurreny';
import { getBankAccountDetails } from '../../Invest/_redux/investmentAction';
import Alert from '../../Common/Alert';
import { RecurringPaymentInstruction } from './RecurringPaymentInstruction';

const PayWithBankTransfer = ({ currency }) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [account, setAccount] = useState({});
  const { currencies: allCurrencies } = useSelector(state => state?.currencies);

  useEffect(() => {
    const selectedCurrency = findCurrency(allCurrencies, currency);
    const getData = async () => {
      try {
        const {
          data: { data },
        } = await getBankAccountDetails(selectedCurrency?.id);
        setAlertOn(false);
        setAccount(data);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response?.data?.message,
          alertMessageType: 'error',
        });
      }
    };

    getData();
  }, [allCurrencies, currency]);

  return (
    <div className="py-md-10 py-5 px-5">
      <div>
        {alertOn && (
          <div className="mb-5">
            <Alert alert={alert} />
          </div>
        )}
        <div className="px-md-5 px-3 py-5">
          <div className="text-center mb-5">
            <h1 className="mb-10 text-20 font-weight-600">
              Set up Bank Recurring Payment
            </h1>
          </div>

          <RecurringPaymentInstruction />
          <BankDetails account={account} />
        </div>
      </div>
    </div>
  );
};

export default PayWithBankTransfer;
