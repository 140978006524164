import React from 'react';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { messages } from '../../../utils/messages';
import { FundType, status } from '../../../helpers/constants';
import { GoalOrPlanDetailsOneItem } from './GoalOrPlanDetailsOneItem';

const GoalDetails = ({
  currency,
  recurringAmount,
  classes,
  averageYtd,
  riskLevel,
  goalAmount,
  fundPortfolioValue,
  transactions,
  goalPortfolioValue,
  depositPendingAmount,
  withdrawPendingAmount,
  profit,
  plan,
}) => {
  const content = <div>{messages.pendingTransactions.currentValue}</div>;

  const hasPendingTransaction = transactions?.some(
    transaction =>
      transaction.transactionStatus === status.PENDING ||
      transaction.transactionStatus === status.TRANSFER_INCOMPLETE,
  );

  return (
    <div className={classes}>
      {(goalPortfolioValue || goalPortfolioValue === 0) && (
        <GoalOrPlanDetailsOneItem
          hasPendingTransactions={hasPendingTransaction}
          hasInformationIcon={true}
          label={'Current Value'}
          value={currencyHelper(goalPortfolioValue, currency)}
          popoverComponentContent={content}
        />
      )}

      {goalAmount && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Target'}
          value={currencyHelper(goalAmount, currency)}
        />
      )}

      {riskLevel && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Risk Level'}
          value={riskLevel ? riskLevel : '---'}
        />
      )}

      {plan?.lockInPeriod && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Lock In Period'}
          value={plan?.lockInPeriod ? `${plan.lockInPeriod} months*` : '---'}
        />
      )}

      {averageYtd && plan?.fundType !== FundType.SAVE && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={`Last Year's Return`}
          value={averageYtd ? `${averageYtd}%` : '---'}
        />
      )}

      {profit && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Profit'}
          value={`${profit}%`}
        />
      )}

      {(recurringAmount || recurringAmount === 0) && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Monthly Investment'}
          value={currencyHelper(recurringAmount, currency)}
        />
      )}

      {(fundPortfolioValue || fundPortfolioValue === 0) && (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={true}
          hasPendingTransactions={hasPendingTransaction}
          popoverComponentContent={content}
          label={'Current Value'}
          value={currencyHelper(fundPortfolioValue, currency)}
        />
      )}

      {depositPendingAmount && depositPendingAmount > 0 ? (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Deposit Received'}
          value={currencyHelper(depositPendingAmount, currency)}
        />
      ) : (
        <div style={{ color: '#ffffff' }}>{'---'}</div>
      )}

      {withdrawPendingAmount && withdrawPendingAmount > 0 ? (
        <GoalOrPlanDetailsOneItem
          hasInformationIcon={false}
          label={'Pending Disinvestment'}
          value={currencyHelper(withdrawPendingAmount, currency)}
        />
      ) : (
        <div style={{ color: '#ffffff' }}>{'---'}</div>
      )}
    </div>
  );
};

export default GoalDetails;
