import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { ConfirmButton, CustomButton } from '../../Common/ReusableButtons';
import { DoYouHaveAccount } from '../Components/DoYouHaveAccount';
import { handleGoogleSignUpAndSignIn } from '../../../helpers/googleAuth';
import Alert from '../../Common/Alert';

export const SelectSignupOption = () => {
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [googleAuthLoading, setGoogleAuthLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);

  const handleSignupWithGoogle = async () => {
    setGoogleAuthLoading(true);
    const response = await handleGoogleSignUpAndSignIn();

    if (response.success) {
      dispatch(
        investorProfileActions.profileAction({
          ...profile,
          user: {
            ...profile?.user,
            ...response?.user,
            hasSignedInWithGoogle: true,
          },
        }),
      );

      localStorage.setItem('idToken', response?.idToken);
      history.push('/auth/registration');
    } else {
      setAlert({
        alertMessage: response?.error?.errorMessage,
        alertMessageType: 'error',
      });
    }
    setGoogleAuthLoading(false);
  };

  return (
    <div className="login-form login-signin">
      <div className="mb-5">
        {alert?.alertMessage ? <Alert alert={alert} /> : null}
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mobile-row">
        <ConfirmButton
          buttonText="Sign up with email"
          buttonWidth={'w-100'}
          changeText={false}
          handleOnClick={() => history.push('/auth/registration')}
        />
      </div>

      <div className="w-100 text-14 font-weight-500 text-center my-5 ">OR</div>

      <div className="form-group d-flex flex-wrap justify-content-between align-items-center mobile-row">
        <CustomButton
          buttonText="Sign up with Google"
          buttonWidth={'w-100'}
          changeText={false}
          classes={'border-1-gray-350 bg-gray-450'}
          addBorder
          handleOnClick={handleSignupWithGoogle}
          buttonIcon={'/media/logos/google.svg'}
          loading={googleAuthLoading}
          spinnerColor="spinner-black"
        />
      </div>

      <DoYouHaveAccount
        question="Already have an account?"
        action={'Sign in '}
        link={'/auth/login'}
        margin="mt-n3"
      />
    </div>
  );
};
