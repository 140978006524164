import React from 'react';
import { PayWithBankTransferCardsWrapper } from '../../Invest/components/PayWithBankTransfer/PayWithBankTransferCardsWrapper';
import { InstructionStep } from '../../Invest/components/PayWithBankTransfer/InstructionStep';

export const RecurringPaymentInstruction = () => {
  return (
    <PayWithBankTransferCardsWrapper>
      <div className="bank-transfer-instructions-wrapper border-radius-8">
        <div className="text-14 font-weight-600 mb-2">Instructions:</div>
        <InstructionStep
          text={
            <div className="d-flex flex-column">
              <span>
                <span className="font-weight-700">
                  (a) Internet Banking Set-up-
                </span>
                Access your bank account on our internet banking portal. Under
                the payments option, choose “Standing Order or Recurring
                Payment” and fill in the required details, including the
                recipient's account information, amount, and frequency.
              </span>
              <span>
                <span className="font-weight-700">(b) Bank Set-up-</span>
                Stop by any branch at your convenience, you will be required to
                fill in a standing order form, including recipient's account
                information, amount, and frequency.
              </span>
            </div>
          }
          stepNumber={1}
          noCenter={true}
        />

        <InstructionStep
          text={
            <span>
              The indicated total deposit amount does not include bank charges.
              Make sure to{' '}
              <span className="font-weight-700">cover all bank charges</span>{' '}
              from your bank and the receiving bank.
            </span>
          }
          stepNumber={2}
        />

        <InstructionStep
          text={
            <span>
              Ensure to indicate the{' '}
              <span className="font-weight-700">Transfer Reference Code</span>{' '}
              as per below table.
            </span>
          }
          stepNumber={3}
        />
      </div>
    </PayWithBankTransferCardsWrapper>
  );
};
