import moment from 'moment';

export const extractDateParts = date => {
  if (!date) return { year: null, month: null, day: null };

  // Parse the date with Moment
  const parsedDate = moment(
    date,
    [
      'YYYY-MM-DD', // 2024-09-03
      'MM/DD/YYYY', // 09/03/2024
      'DD/MM/YYYY', // 18/08/1995
      'DD.MM.YYYY', // 03.09.2024
      'YYYY/MM/DD', // 2024/09/03
    ],
    true,
  ); // `true` enables strict parsing

  if (!parsedDate.isValid()) {
    return null;
  }

  const year = parsedDate.year();
  const month = parsedDate.month() + 1; // `.month()` is zero-based
  const day = parsedDate.date();

  return { year, month: month < 10 ? `0${month}` : month, day };
};
