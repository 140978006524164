import React from 'react';
import SelectPhoneNumber from '../../Common/SelectPhoneNumber';
import { ValidationErrors } from '../../Business/components/ValidationErrors';

function MobileMoney({
  phoneNumber,
  setPhoneNumber,
  selectedCountry,
  validationErrors,
}) {
  const onHandleChange = e => {
    const value = e.target.value;

    if (isNaN(value)) {
      // Exit the function if the value is not a number
      return;
    } else {
      setPhoneNumber(value);
    }
  };

  return (
    <>
      <section className="px-md-20 px-3">
        <div className="d-flex justify-content-center  flex-column flex-wrap   center-input mt-7">
          <h1 className="mobile-money-heading">Enter your mobile number</h1>

          <div className="d-flex w-100">
            <div className="width-40">
              <SelectPhoneNumber
                selectedCountry={selectedCountry}
                disableInput={true}
              />
            </div>
            <div className="ml-2 width-60">
              <input
                name="phoneNumber"
                className={`form-control border-gray-450 border-radius-8 height-45 input-field`}
                type="tel"
                autoComplete="off"
                value={phoneNumber}
                onChange={e => onHandleChange(e)}
              />

              {validationErrors && (
                <ValidationErrors errorMessage={validationErrors} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MobileMoney;
