import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { PhoneNumberInput } from '../../Common/PhoneNumberInput';
import { phoneNumberVerificationInitialValues } from '../InitialValues';
import { phoneNumberVerificationSchema } from '../Schemas';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { getCountryDetails } from '../../../helpers/getCountryDetails';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import getCountries from '../../../helpers/countries';

const PhoneNumberVerification = ({ intl }) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [open, setOpen] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  const profile = useSelector(state => state.profile);

  const history = useHistory();

  useEffect(() => {
    const countryDetails = getCountryDetails(profile?.investor?.country);

    setSelectedCountry(countryDetails);
  }, [profile]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: phoneNumberVerificationInitialValues(),
    validationSchema: phoneNumberVerificationSchema(intl),
    onSubmit: async values => {
      const fullPhoneNumber = getFullPhoneNumber(
        selectedCountry?.countryCallingCode,
        values.phoneNumber,
      );

      const isValid = validatePhoneNumber(fullPhoneNumber, values.phoneNumber);

      setValidPhoneNumber(isValid);
      try {
      } catch (error) {}
    },
  });

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpen(false);
  };

  return (
    <>
      <div className="login-form login-signin">
        <div className="d-flex flex-wrap justify-content-between align-items-center mobile-row">
          {/* TODO: use ExternalPageTitle component when we add phone verification feature */}

          <div className="text-18 font-weight-500 mx-auto">
            Enter your phone number
          </div>
          {false ? (
            <div className="text-13 text-blue text-center mx-auto mt-2">
              We will send a verification code on your mobile number to confirm
              it’s you
            </div>
          ) : null}

          <PhoneNumberInput
            setOpen={setOpen}
            selectedCountry={selectedCountry}
            formik={formik}
            validPhoneNumber={validPhoneNumber}
          />
          <ConfirmButton
            buttonText="Next"
            buttonWidth={'w-100'}
            classes={'mt-10'}
            changeText={false}
            handleOnClick={() => {
              history.push('/auth/selectAccountType');
            }}
          />
        </div>
      </div>

      <SelectOptionsModal
        open={open}
        handleClose={() => setOpen(false)}
        allCountries={getCountries()}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />
    </>
  );
};

export default injectIntl(connect(null, null)(PhoneNumberVerification));
