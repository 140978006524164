import jwtDecode from 'jwt-decode';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const authToken = localStorage.getItem('ndovuToken');
      const idToken = localStorage.getItem('idToken');

      if (idToken) {
        config.headers['id-token'] = idToken;
      }

      if (authToken && authToken !== null) {
        const decodedToken = jwtDecode(authToken);
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('ndovuToken');
          window.location.replace('/auth/login');
          return;
        }
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err),
  );
}
