/* eslint-disable jsx-a11y/anchor-is-valid */

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import './../index.scss';

import { Link, Route, Switch, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AgeWizardPage from './age';
import CashSavings from './cashSavings';
import DisplayAppropriateContent from '../../Common/DisplayAppropriateContent';
import ExternaLeft from '../../Common/ExternalLeft';
import FamiliarInvestments from './familiarInvestments';
import InvestmentAttitude from './investmentAttitude';
import InvestmentExperience from './investmentExperience';
import MonthlyIncomePage from './monthlyIncome';
import MonthlySavingsPage from './monthlySavings';
import Purpose from './purpose';
import TenPercentInvestmentLoss from './tenPercentInvestmentLoss';
import TimeFrame from './timeFrame';
import WizardHomePage from './wizardHomePage';
import Registration from '../../Auth/pages/Registration';

const WizardPage = props => {
  const history = useHistory();
  const [retakingWizard, setRetakingWizard] = useState(false);
  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.prevPath === '/account/investorType'
    ) {
      setRetakingWizard(true);
    }
  }, [props.location]);

  // TODO: THIS IS HACK USE A BETTER SOLUTION
  const alignContentOnRegistrationPage =
    history.location.pathname === '/wizard/personalDetails'
      ? 'justify-content-center align-items-center'
      : '';

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Aside*/}
          <ExternaLeft>
            <DisplayAppropriateContent />
          </ExternaLeft>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div
            className={`flex-row-fluid d-flex flex-column ${alignContentOnRegistrationPage} position-relative p-0 overflow-hidden`}
          >
            {/* begin::Content body */}
            <div className="d-flex flex-column flex-left mt-5 mt-lg-0">
              <Switch>
                <Route
                  exact
                  path={`${props.match.path}`}
                  component={() => <WizardHomePage />}
                />
                <Route
                  exact
                  path={`${props.match.path}/age`}
                  component={() => <AgeWizardPage />}
                />
                <Route
                  exact
                  path={`${props.match.path}/purpose`}
                  component={() => <Purpose />}
                />
                <Route
                  exact
                  path={`${props.match.path}/monthlyIncome`}
                  component={() => <MonthlyIncomePage />}
                />
                <Route
                  exact
                  path={`${props.match.path}/monthlySavings`}
                  component={() => <MonthlySavingsPage />}
                />
                <Route
                  exact
                  path={`${props.match.path}/cashSavings`}
                  component={() => <CashSavings />}
                />
                <Route
                  exact
                  path={`${props.match.path}/timeFrame`}
                  component={() => <TimeFrame />}
                />
                <Route
                  exact
                  path={`${props.match.path}/investmentExperience`}
                  component={() => <InvestmentExperience />}
                />
                <Route
                  exact
                  path={`${props.match.path}/investmentAttitude`}
                  component={() => <InvestmentAttitude />}
                />
                <Route
                  exact
                  path={`${props.match.path}/tenPercentInvestmentLoss`}
                  component={() => (
                    <TenPercentInvestmentLoss
                      loggedIn={
                        props.profile && props.profile.accessToken
                          ? true
                          : false
                      }
                      retakingWizard={retakingWizard}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${props.match.path}/familiarInvestments`}
                  component={() => <FamiliarInvestments />}
                />
                <Route
                  exact
                  path={`${props.match.path}/personalDetails`}
                  component={() => <Registration fromWizard={true} />}
                />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div
                className="font-weight-bold order-2 order-sm-1 my-2"
                style={{ color: '#0071CE' }}
              >
                &copy; {new Date().getFullYear()} ndovu |{' '}
                <Link to="#!">Privacy</Link> | <Link to="#!">Legal</Link> |{' '}
                <Link to="#!">Contact</Link>
              </div>
              {/* remove div for links */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { profile: state.auth.authToken };
};
export default connect(mapStateToProps, null)(WizardPage);
