/**
 * Entry application component used to compose providers and render Routes.
 * */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';

import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import InvalidateCache from './InvalidateCache';
import { PersistGate } from 'redux-persist/integration/react';
import StandardErrorBoundary from './pages/StandardErrorBoundary';

export default function App({ store, persistedStore, basename }) {
  return (
    <InvalidateCache>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) refreshCacheAndReload();

        return (
          /* Provide Redux store */
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore}>
              {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
              <React.Suspense fallback={<LayoutSplashScreen />}>
                {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                <StandardErrorBoundary>
                  <BrowserRouter basename={basename}>
                    {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                    <MaterialThemeProvider>
                      {/* Provide `react-intl` context synchronized with Redux state.  */}
                      <I18nProvider>
                        {/* Render routes with provided `Layout`. */}
                        <Routes />
                      </I18nProvider>
                    </MaterialThemeProvider>
                  </BrowserRouter>
                </StandardErrorBoundary>
              </React.Suspense>
            </PersistGate>
          </Provider>
        );
      }}
    </InvalidateCache>
  );
}
