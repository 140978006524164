import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { CancelButton, ConfirmButton } from './ReusableButtons';
import { ButtonsContainer } from './ButtonsContainer';
import CheckBox from './CheckBox';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '455px',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
}));

export const ApproveRecurringPaymentModal = ({
  open,
  setOpen,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  showIcon = true,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [checked, handleCheckBoxChange] = useState(false);

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${moreClasses} border-radius-8 pt-5 pb-8 px-10`}
    >
      {addCloseButton ? (
        <div
          onClick={setOpen}
          className="bg-blue text-white border-radius-3 font-weight-500 ml-auto cursor-pointer"
          style={{
            paddingBottom: '1px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          x
        </div>
      ) : null}
      <div className="d-flex justify-content-center align-items-center flex-column">
        {showIcon ? (
          <div className="my-10">
            <SVG
              className="mb-1 mr-5"
              src={toAbsoluteUrl(`/media/svg/ndovu/approved.svg`)}
              style={{ width: '72px', height: '72px' }}
            />
          </div>
        ) : null}
      </div>

      <div className="modal-summary-body text-center">
        <h6>Confirm set up by clicking the confirmation below</h6>
      </div>

      <div className="pt-3">
        <CheckBox
          checked={checked}
          label={`I have set up monthly recurring payment`}
          name={''}
          handleCheckBoxChange={() => handleCheckBoxChange(!checked)}
        />
      </div>

      <div className="w-100">
        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={setOpen}
            twinButton
          />

          <ConfirmButton
            buttonText={'Confirm'}
            handleOnClick={handleButtonClick}
            twinButton
            disable={!checked}
          />
        </ButtonsContainer>
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};
