import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import radioBtn from '../../images/radioBtn.svg';
import radioBtnSelected from '../../images/radioBtnSelected.svg';
import { ButtonsContainer } from './ButtonsContainer';
import { CancelButton, ConfirmButton } from './ReusableButtons';
import { useHistory } from 'react-router-dom';
function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '742px',
    maxHeight: '90vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const SelectListModal = ({
  open,
  handleSelect,
  handleClose,
  items,
  selectedItem,
}) => {
  const [selected, setSelected] = useState(null);
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [filteredOptions, setFilteredOptions] = useState();
  const history = useHistory();
  useEffect(() => {
    selectedItem &&
      items &&
      items.forEach((item, i) => {
        if (item.value === selectedItem.value) {
          items.splice(i, 1);
          items.unshift(item);
        }
      });
    setFilteredOptions(items);
  }, [items, selectedItem]);

  const handleCloseButton = () => {
    setSelected(null);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <div className="text-12 text-center font-weight-500 withdraw-settlement-title">
              Select Investment method
            </div>

            <div className="w-100 mt-5">
              {filteredOptions?.map(item => (
                <div key={item.key} className={'account-cards'}>
                  <div className="card-left">
                    <div
                      className={`icon`}
                      onClick={() => setSelected(item.key)}
                    >
                      {selected === item.key ? (
                        <img src={radioBtnSelected} alt="Radio button" />
                      ) : (
                        <img src={radioBtn} alt="Selected radio button" />
                      )}
                    </div>
                  </div>

                  <div className="card-right justify-content-center">
                    <span className="account-title-single-items">
                      {item.value}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <ButtonsContainer justifyContent="justify-content-between">
              <CancelButton
                buttonText="Cancel"
                handleOnClick={() => handleCloseButton()}
                twinButton
              />
              <ConfirmButton
                buttonText={'Next'}
                handleOnClick={() => handleSelect(selected)}
                disable={!selected}
                twinButton
              />
            </ButtonsContainer>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectListModal;
