import { gapi } from 'gapi-script';
import jwtDecode from 'jwt-decode';

export const initGoogleSignIn = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return new Promise((resolve, reject) => {
    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id: clientId,
        })
        .then(() => {
          // Resolve with the Auth instance
          resolve(gapi.auth2.getAuthInstance());
        })
        .catch(error => {
          reject(error);
        });
    });
  });
};

export const handleGoogleSignUpAndSignIn = async () => {
  // Get the authentication instance after initialization
  const auth2 = await initGoogleSignIn();

  if (!auth2) {
    console.log('GoogleAuth instance not initialized');
    return {
      success: false,
      errorMessage: 'Something went wrong, try again please',
    };
  }

  try {
    const googleUser = await auth2.signIn();
    const idToken = googleUser.getAuthResponse()?.id_token;
    const userInformation = jwtDecode(idToken);

    const user = {
      firstName: userInformation?.given_name,
      lastName: userInformation?.family_name,
      email: userInformation?.email,
    };

    return { success: true, user, idToken };
  } catch (error) {
    console.log('Google Sign-In error', error);
    return {
      success: false,
      errorMessage: 'Something went wrong, try again please',
    };
  }
};
