import React from 'react';
import { ConfirmButton } from '../../Common/ReusableButtons';
import CommonButton from '../../Common/Button';
const RecurringPaymentsData = ({
  id,
  setOpenRecurringPaymentModal,
  recurringPaymentData = null,
  onClick = null,
}) => {
  return (
    <>
      <div className="mb-3 font-weight-bold d-flex justify-content-between align-items-center">
        <span className="mt-2 label-styles text-15 text-blue">
          Fund value details
        </span>
        {recurringPaymentData === null ? (
          <div>
            <ConfirmButton
              buttonText={'Set up Recurring investments'}
              handleOnClick={() => setOpenRecurringPaymentModal()}
              disable={false}
              loading={false}
              buttonWidth={'width-275'}
            />
          </div>
        ) : (
          <CommonButton
            btnText="Confirm Pending recurring payment"
            className="text-center full-width-small approve-recurring-btn  text-blue"
            onClick={onClick}
          />
        )}
      </div>
    </>
  );
};

export default RecurringPaymentsData;
