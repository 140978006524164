import { countries } from 'countries-list';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { checkForErrors } from '../../../helpers/checkForErrors';
import {
  EmploymentStatus,
  SourceOfFunds,
  SourceOfFundsObject,
  allowWhiteSpace,
  allowWhiteSpaceError,
  genderOptions,
} from '../../../helpers/constants';
import getCountries from '../../../helpers/countries';
import { extractDateParts } from '../../../helpers/Date/extractDateParts';
import { getGender } from '../../../helpers/getGender';
import {
  days,
  getDaysInMonth,
  months,
  getYears as years,
} from '../../../helpers/investmentHelpers';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import Alert from '../../Common/Alert';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import CustomInput from '../../Common/CustomInput';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import OptionsModal from '../../Common/OptionsModal';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import SelectPhoneNumber from '../../Common/SelectPhoneNumber';
import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import { DateValidation } from '../components/DateValidation';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { ReasonForInformation } from '../components/ReasonForInformation';

const InvestorDetails = props => {
  const { id: stepperId, handleStepChange } = props;
  const profile = useSelector(state => state?.profile);
  const extracted = useSelector(state => state?.kyc);

  const { investor, user } = profile;

  const history = useHistory();
  const [updatedDays, setUpdatedDays] = useState(days);
  const [selectedYear, setSelectedYear] = useState();
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [openDropdown, setOpenDropdown] = useState(false);
  const [
    openEmploymentStatusDropdown,
    setOpenEmploymentStatusDropdown,
  ] = useState(false);
  const [openSourceOfFund, setOpenSourceOfFund] = useState(false);
  const [openCountryCallingCode, setOpenCountryCallingCode] = useState(false);
  const allCountries = getCountries();

  const [selectedOption, setSelectedOption] = useState({
    gender: getGender(extracted?.gender ? extracted?.gender : investor?.gender),
    employmentStatus: {
      label: extracted?.employmentStatus
        ? extracted?.employmentStatus
        : investor?.employmentStatus
        ? investor?.employmentStatus
        : null,
      value: extracted?.employmentStatus
        ? extracted?.employmentStatus
        : investor?.employmentStatus
        ? investor?.employmentStatus
        : null,
    },

    sourceOfFunds: {
      value: extracted?.sourceOfFunds
        ? extracted?.sourceOfFunds
        : investor?.sourceOfFunds
        ? investor?.sourceOfFunds
        : null,
      label:
        investor?.sourceOfFunds || extracted?.sourceOfFunds
          ? SourceOfFundsObject[
              extracted?.sourceOfFunds
                ? extracted?.sourceOfFunds
                : investor?.sourceOfFunds
            ]
          : null,
    },
  });

  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState(
    extracted?.nextOfKinContact || investor?.nextOfKinContact
      ? {
          countryCallingCode: parsePhoneNumber(
            extracted?.nextOfKinContact
              ? extracted?.nextOfKinContact
              : investor?.nextOfKinContact,
          ).countryCallingCode,

          name:
            countries[
              parsePhoneNumber(
                extracted?.nextOfKinContact
                  ? extracted?.nextOfKinContact
                  : investor?.nextOfKinContact,
              ).country
            ]?.name,

          flag: `flag-icon-${parsePhoneNumber(
            extracted?.nextOfKinContact
              ? extracted?.nextOfKinContact
              : investor?.nextOfKinContact,
          ).country.toLowerCase()}`,
        }
      : {
          countryCallingCode: '254',
          name: 'Kenya',
          flag: `flag-icon-ke`,
        },
  );

  const [errorExist, setErrorExist] = useState();

  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  useEffect(() => {
    handleStepChange({ next: stepperId });
  }, []);

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const { year, month, day } = extractDateParts(
    extracted?.dateOfBirth ? extracted?.dateOfBirth : investor?.dateOfBirth,
  );

  const initialValues = {
    identificationNumber:
      extracted?.identificationNumber ||
      investor?.personalIdentity?.identificationNumber ||
      '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    year,
    month,
    day,
    city: extracted?.city || investor?.city || '',
    nextOfKinNames: extracted?.nextOfKinNames || investor?.nextOfKinNames || '',
    nextOfKinContact:
      investor?.nextOfKinContact || extracted?.nextOfKinContact
        ? parsePhoneNumber(
            extracted?.nextOfKinContact
              ? extracted?.nextOfKinContact
              : investor?.nextOfKinContact,
          )?.nationalNumber
        : '',

    nextOfKinEmail: extracted?.nextOfKinEmail || investor?.nextOfKinEmail || '',
  };

  const initialValuesStageTwo = {
    residentialAddress:
      extracted?.residentialAddress || investor?.residentialAddress || '',
    postalCode: extracted?.postalCode || investor?.postalCode || '',
  };

  const initialValuesForStageOneAndTwo = {
    ...initialValues,
    ...initialValuesStageTwo,
  };

  const DetailsSchema = Yup.object().shape({
    identificationNumber: Yup.string()
      .min(1, 'Required Field')
      .required('Identification number is Required')
      .matches(allowWhiteSpace, allowWhiteSpaceError),

    year: Yup.string()
      .min(1, 'Required Field')
      .required('Year is Required'),

    month: Yup.string()
      .min(1, 'Required Field')
      .required('Month is Required'),

    day: Yup.string()
      .min(1, 'Required Field')
      .required('Day is Required'),

    city: Yup.string()
      .min(1, 'Required Field')
      .required('City is Required')
      .matches(allowWhiteSpace, allowWhiteSpaceError),

    nextOfKinNames: Yup.string()
      .min(1)
      .required('Next of Kin Full Name is Required')
      .matches(allowWhiteSpace, allowWhiteSpaceError),

    nextOfKinEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters'),
  });

  const DetailsSchemaStageTwo = Yup.object().shape({
    residentialAddress: Yup.string()
      .trim()
      .min(1, 'Residential Address is Required')
      .required('Residential Address is Required'),
    postalCode: Yup.string()
      .trim()
      .min(1, 'Postal Code is Required')
      .required('Postal Code is Required'),
  });

  const DetailsSchemaForStageOneAndTwo = Yup.object().shape({
    ...DetailsSchema.fields,
    ...DetailsSchemaStageTwo.fields,
  });

  // When extra mandatory fields are added to kyc tier 2, this array needs to be updated
  const kycTwoMandatoryFields = ['residentialAddress', 'postalCode'];

  const onlyKycOneFieldsAreRequired =
    investor?.kycLevel?.level === 1 ||
    investor?.pendingKyc?.every(
      field => !kycTwoMandatoryFields.includes(field),
    );

  const onlyKycTwoFieldsAreRequired =
    investor?.kycLevel?.level >= 2 &&
    investor?.pendingKyc?.some(field => kycTwoMandatoryFields.includes(field));

  const kycOneAndTwoFieldsAreRequired =
    onlyKycOneFieldsAreRequired && onlyKycTwoFieldsAreRequired;

  const fields = {
    country: selectedCountry.countryCallingCode,
    gender: selectedOption.gender.value,
    employmentStatus: selectedOption.employmentStatus.value,
    sourceOfFunds: selectedOption.sourceOfFunds.value,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: kycOneAndTwoFieldsAreRequired
      ? initialValuesForStageOneAndTwo
      : onlyKycTwoFieldsAreRequired
      ? initialValuesStageTwo
      : initialValues,
    validationSchema: kycOneAndTwoFieldsAreRequired
      ? DetailsSchemaForStageOneAndTwo
      : onlyKycTwoFieldsAreRequired
      ? DetailsSchemaStageTwo
      : DetailsSchema,
  });

  const handleSubmit = async () => {
    const nextOfKinEmail = formik?.values?.nextOfKinEmail
      ?.toLowerCase()
      ?.trim();

    const { sourceOfFunds, employmentStatus, gender } = selectedOption;
    const fullPhoneNumber =
      formik.values.nextOfKinContact && selectedCountry?.countryCallingCode
        ? getFullPhoneNumber(
            selectedCountry?.countryCallingCode,
            formik.values.nextOfKinContact,
          )
        : null;

    const isValid = fullPhoneNumber
      ? validatePhoneNumber(fullPhoneNumber, formik.values.nextOfKinContact)
      : false;

    if (onlyKycOneFieldsAreRequired || kycOneAndTwoFieldsAreRequired) {
      setValidPhoneNumber(isValid);

      const noErrors = checkForErrors({ ...formik?.values, ...fields }, [
        'nextOfKinEmail',
      ]);

      if (!noErrors) {
        setErrorExist(true);
        return;
      }

      if (!isValid) {
        return;
      }
    }

    const fieldsToValidate =
      onlyKycOneFieldsAreRequired || kycOneAndTwoFieldsAreRequired
        ? { ...formik?.values, nextOfKinEmail, ...fields }
        : { ...formik?.values, nextOfKinEmail };

    const noErrors = checkForErrors(fieldsToValidate, ['nextOfKinEmail']);

    if (!noErrors) {
      setErrorExist(noErrors);
      return;
    }

    const { year, month, day } = formik?.values;

    delete formik?.values?.day;
    delete formik?.values?.month;
    delete formik?.values?.year;

    if (nextOfKinEmail && formik?.values?.nextOfKinEmail)
      formik.values.nextOfKinEmail = nextOfKinEmail;

    let payload =
      onlyKycOneFieldsAreRequired || kycOneAndTwoFieldsAreRequired
        ? {
            ...formik?.values,
            sourceOfFunds: sourceOfFunds?.value,
            employmentStatus: employmentStatus?.value,
            gender: gender?.value,
            dateOfBirth: moment(`${year}-${month}-${day}`).format('YYYY-MM-DD'),
          }
        : { ...formik?.values };

    payload =
      fullPhoneNumber && isValid
        ? { ...payload, nextOfKinContact: fullPhoneNumber }
        : payload;

    payload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key] && payload[key]?.length) acc[key] = payload[key];
      return acc;
    }, {});

    dispatch(kycActions.updateKycInfo(payload));

    const redirectLink = onlyKycOneFieldsAreRequired
      ? '/kyc-information/summary'
      : '/kyc-information/upload-documents';

    history.push(redirectLink);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleEmploymentStatusDropdown = () => {
    setOpenEmploymentStatusDropdown(!openEmploymentStatusDropdown);
  };

  const handleSourceOfFundsDropdown = () => {
    setOpenSourceOfFund(!openSourceOfFund);
  };

  const closeEmploymentStatusDropdown = () => {
    setOpenEmploymentStatusDropdown(false);
  };

  const closeSourceOfFundDropdown = () => {
    setOpenSourceOfFund(false);
  };

  const handleSelectOption = (item, name) => {
    setSelectedOption({ ...selectedOption, [name]: item });
    closeDropdown();
    closeEmploymentStatusDropdown();
    closeSourceOfFundDropdown();
  };

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpenCountryCallingCode(false);
  };

  return (
    <>
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        {alertOn && <Alert alert={alert} />}

        <KycPageContentWrapper>
          {onlyKycOneFieldsAreRequired ? (
            <>
              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="First Name"
                  name="firstName"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                  disabled={true}
                />

                <CustomInput
                  type="text"
                  title="Last Name"
                  name="lastName"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                  disabled={true}
                />
              </div>
              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="ID Number"
                  name="identificationNumber"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />

                <DropdownContainer>
                  <DropdownTitle title={'Gender'} />

                  <ReusableDropdown
                    handleOpenSelector={handleDropdown}
                    dropdownPlaceholder={'Gender'}
                    selectedOption={selectedOption?.gender?.label}
                    classes={
                      'py-3 height-45 border-radius-6 border-light-blue-400 pr-2'
                    }
                    name={'gender'}
                    required={true}
                  />

                  <FieldErrorMessage
                    errorExist={errorExist}
                    errorMessage={'Gender is Required'}
                    fieldValue={selectedOption.gender.value}
                  />
                </DropdownContainer>
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Date of Birth'} />

                  <div className="dateOfBirthInput flex-wrap">
                    <div className="d-flex extracted-year">
                      <select
                        name="year"
                        className={`single-select text-13 text-dark-600 ${
                          formik?.values?.year?.length ? 'font-weight-500' : ''
                        } ${getInputClasses('year')}`}
                        {...formik.getFieldProps('year')}
                        onChange={event => {
                          formik.setFieldValue('year', event.target.value);
                          // Set the selected year here
                          setSelectedYear(event.target.value);
                        }}
                      >
                        <option value="" disabled hidden>
                          Year
                        </option>
                        {years().map(year => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="d-flex extracted-month">
                      <select
                        name="month"
                        className={`single-select text-15 text-dark-600 ${
                          formik?.values?.month?.length ? 'font-weight-500' : ''
                        }`}
                        {...formik.getFieldProps('month')}
                        onChange={event => {
                          formik.setFieldValue('month', event.target.value);
                          const selectedMonth = event.target.value;
                          // Calculate and set the days based on the selected month
                          setUpdatedDays(
                            getDaysInMonth(selectedMonth, selectedYear),
                          );
                        }}
                      >
                        <option value="" disabled hidden>
                          Month
                        </option>
                        {months.map(month => (
                          <option key={month.value} value={month.value}>
                            {month.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="d-flex extracted-day">
                      <select
                        name="day"
                        className={`single-select text-15 text-dark-600 ${
                          formik?.values?.day?.length ? 'font-weight-500' : ''
                        }`}
                        {...formik.getFieldProps('day')}
                      >
                        <option value="" disabled hidden>
                          Day
                        </option>
                        {updatedDays.map(day => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <DateValidation formik={formik} />
                </DropdownContainer>

                <CustomInput
                  type="text"
                  title="City"
                  name="city"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              </div>
            </>
          ) : null}

          {onlyKycTwoFieldsAreRequired ? (
            <div className="d-flex justify-content-between flex-wrap">
              <CustomInput
                type="text"
                title="Residential Address"
                name="residentialAddress"
                formik={formik}
                getInputClasses={getInputClasses}
                required={true}
              />

              <CustomInput
                type="text"
                title="Postal Code"
                name="postalCode"
                formik={formik}
                required={true}
                getInputClasses={getInputClasses}
              />
            </div>
          ) : null}

          {onlyKycOneFieldsAreRequired ? (
            <>
              <div className="blue-line mt-15"></div>

              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Employment Status'} />

                  <ReusableDropdown
                    handleOpenSelector={handleEmploymentStatusDropdown}
                    dropdownPlaceholder={'Employment Status'}
                    selectedOption={selectedOption?.employmentStatus?.label}
                    classes={
                      'py-3 height-45 border-light-blue-400 border-radius-6 pr-2'
                    }
                    name={'employmentStatus'}
                  />

                  <FieldErrorMessage
                    errorExist={errorExist}
                    errorMessage={'Employment Status is Required'}
                    fieldValue={selectedOption.employmentStatus.value}
                  />
                </DropdownContainer>

                <DropdownContainer>
                  <DropdownTitle title={'Source of Funds'} />

                  <ReusableDropdown
                    handleOpenSelector={handleSourceOfFundsDropdown}
                    dropdownPlaceholder={'Source Of Funds'}
                    selectedOption={selectedOption?.sourceOfFunds?.label}
                    classes={
                      'py-3 height-45 border-light-blue-400 border-radius-6 pr-2'
                    }
                    name={'sourceOfFunds'}
                  />

                  <FieldErrorMessage
                    errorExist={errorExist}
                    errorMessage={'Source of Funds is Required'}
                    fieldValue={selectedOption.sourceOfFunds.value}
                  />
                </DropdownContainer>
              </div>

              <div className="blue-line mt-15"></div>

              <ReasonForInformation
                title={'Reason for this Information:'}
                content={
                  "Next of Kin is a requirement by law. We will only contact this person in the eventuality of your demise or we aren't able to reach you for a prolonged period of time."
                }
              />

              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="Next of Kin Full Name"
                  name="nextOfKinNames"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />

                <DropdownContainer>
                  <DropdownTitle title={'Next of Kin Contact'} />

                  <div className="d-flex w-100">
                    <div className="width-40">
                      <SelectPhoneNumber
                        setOpen={setOpenCountryCallingCode}
                        selectedCountry={selectedCountry}
                      />
                    </div>

                    <div className="ml-2 width-60">
                      <input
                        name="nextOfKinContact"
                        className={`w-100 border-gray-450 height-45 border-radius-8 px-4 font-weight-500 text-13 text-dark-600`}
                        type="tel"
                        {...formik.getFieldProps('nextOfKinContact')}
                        autoComplete="off"
                      />

                      {formik.touched.nextOfKinContact &&
                      formik.errors.nextOfKinContact ? (
                        <ValidationErrors
                          errorMessage={formik.errors.nextOfKinContact}
                        />
                      ) : null}

                      {!validPhoneNumber ? (
                        <ValidationErrors
                          errorMessage={
                            'Enter a valid phone number e.g. 7********'
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </DropdownContainer>
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="email"
                  title="Next of Kin Email"
                  name="nextOfKinEmail"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={false}
                />
              </div>
            </>
          ) : null}

          <ButtonsContainer justifyContent="justify-content-between mt-20">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() =>
                history.push(
                  kycOneAndTwoFieldsAreRequired || onlyKycOneFieldsAreRequired
                    ? '/kyc-information/investor-documents'
                    : '/manage-account/investor-info/instructions',
                  { previousStep: 4 },
                )
              }
              twinButton
            />

            <ConfirmButton
              buttonType={'submit'}
              buttonText={'Next'}
              handleOnClick={handleSubmit}
              loading={loading}
              twinButton
            />
          </ButtonsContainer>
        </KycPageContentWrapper>
      </form>

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={genderOptions}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption?.gender}
        placeholder={'Gender'}
        name={'gender'}
      />

      <OptionsModal
        open={openEmploymentStatusDropdown}
        handleClose={() => closeEmploymentStatusDropdown()}
        items={EmploymentStatus}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption?.employmentStatus}
        placeholder={'Employment Status'}
        name={'employmentStatus'}
      />

      <OptionsModal
        open={openSourceOfFund}
        handleClose={() => closeSourceOfFundDropdown()}
        items={SourceOfFunds}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption?.sourceOfFunds}
        placeholder={'Source of Funds'}
        name={'sourceOfFunds'}
      />

      <SelectOptionsModal
        open={openCountryCallingCode}
        handleClose={() => setOpenCountryCallingCode(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />
    </>
  );
};

export default InvestorDetails;
