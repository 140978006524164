import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import SliderInput from '../../Goal/components/GoalCalculator/SliderInput';
import InputValidationErrorMessage from '../../Common/InputValidationErrorMessage';
import { GoalCalculatorInputContainer } from '../../Goal/components/GoalCalculatorInputContainer';
import { useSelector } from 'react-redux';
import { VerticalLine } from '../../Common/VerticalLine';
import Input from '../../Common/Input/ControlledInput';
import CurrenciesModal from '../../Common/CurrenciesModal';
import Dropdown from '../../Common/Dropdown';
import { findCurrency } from '../../../helpers/findCurreny';
import { Currency, paymentOptions } from '../../../helpers/constants';

function RecurringPaymentCalculator(props) {
  const { currencies } = useSelector(state => state.currencies);
  const {
    handleChange,
    handleYearChange,
    years,
    months,
    amount,
    validationError,
    endDate,
    setCurrency,
    recurringPaymentData,
  } = props;

  const initialValues = {
    initialAmount: 0,
    goalAmount: 0,
    currency: recurringPaymentData?.currency
      ? recurringPaymentData?.currency
      : Currency.KES,
    riskLevel: null,
  };

  const maxYears = 5;

  const [targetDateError, setTargetDateError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const currency = findCurrency(currencies, initialValues.currency);
    setSelectedOption(currency);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
  });
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleDropdown = dropdownName => {
    setOpenDropdown(true);
  };

  const handleSelect = (option, name) => {
    setSelectedOption({ ...option });
    setCurrency(option.currency);
    setOpenDropdown(false);
  };

  return (
    <>
      <form id="kt_form" onSubmit={formik.handleSubmit}>
        <div className="w-100 d-flex justify-content-between flex-wrap">
          <div className="mt-5 input-card-container border-light-blue-545 border-radius-8">
            <SliderInput
              formik={formik}
              handleChange={handleChange}
              title="How long are you planning to save for?"
              rangeInputName="targetDate"
              sliderValue={years * 12 + months}
              silderLabels={
                <div className="d-flex justify-content-between mx-auto goal-amount-slider-container mt-1 input-container px-5">
                  <p className="text-11 font-weight-500">1 Month</p>
                  <p className="text-11 font-weight-500">{`${maxYears} Years`}</p>
                </div>
              }
              min={0}
              max={maxYears * 12}
              step={6}
              input={
                <>
                  <div className="d-flex mx-auto justify-content-center goal-amount-slider-container px-5 input-container">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="text-center border-gray-450 height-45 input-styles py-2"
                        style={{
                          width: '85px',
                        }}
                        name="targetDate"
                        id="years"
                        value={years.toString()}
                        onChange={handleYearChange}
                        autoComplete="off"
                      />
                      <span className="ml-1 text-11 font-weight-500">
                        Years
                      </span>
                    </div>

                    <div className="d-flex align-items-center ml-3">
                      <input
                        type="text"
                        className="text-center border-gray-450 height-45 py-2 input-styles"
                        style={{
                          width: '85px',
                        }}
                        name="targetDate"
                        value={months.toString()}
                        id="months"
                        onChange={handleYearChange}
                        autoComplete="off"
                      />
                      <span className="ml-1 text-11 font-weight-500">
                        Months
                      </span>
                    </div>
                  </div>

                  {targetDateError ? (
                    <InputValidationErrorMessage message={targetDateError} />
                  ) : null}
                </>
              }
            />
            {endDate && (
              <div className="text-center mt-2 end-date-note">
                End date - {endDate}
              </div>
            )}
          </div>

          <GoalCalculatorInputContainer
            label={'How much would you like to invest per installment?'}
          >
            <div
              className={`${'border-gray-450'} width-319 d-flex justify-content-between align-items-center border-radius-8 text-center mt-1 px-3 mx-auto`}
            >
              <Input
                placeholder={''}
                type="text"
                className={`width-45 py-4 border-0 bg-transparent font-weight-500`}
                name="amount"
                defaultValue={amount || ''}
                handleChange={handleChange}
              />
              <VerticalLine
                classes={'border-r-gray-450 h-100 position-relative py-5'}
              />

              <div className="width-45">
                <Dropdown
                  handleOpenSelector={handleDropdown}
                  dropdownPlaceholder={''}
                  selectedOption={selectedOption}
                  classes={'bg-transparent'}
                  name="currency"
                  // disable input when paymentChannel is MPESA
                  disableDropdown={
                    recurringPaymentData?.paymentChannel ===
                    paymentOptions.MPESA
                  }
                  withBorders={false}
                />
              </div>
            </div>

            {validationError?.amount && (
              <InputValidationErrorMessage message={validationError?.amount} />
            )}
          </GoalCalculatorInputContainer>

          <CurrenciesModal
            open={openDropdown}
            handleClose={() => setOpenDropdown(false)}
            items={currencies}
            handleSelect={handleSelect}
            selectedItem={selectedOption}
            name={'currency'}
          />
        </div>
      </form>
    </>
  );
}

export default RecurringPaymentCalculator;
