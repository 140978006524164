export const actionTypes = {
  updateRecurringPaymentData: '[updateRecurringPaymentData] Action',
  resetRecurringPaymentData: '[resetRecurringPaymentData] Action',
};

const initialRecurringPaymentState = {};

export const reducer = (state = initialRecurringPaymentState, action) => {
  switch (action.type) {
    case actionTypes.updateRecurringPaymentData: {
      return { ...state, ...action.payload };
    }
    case actionTypes.resetRecurringPaymentData: {
      return {
        ...state,
        initialRecurringPaymentState: {},
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  updateRecurringPaymentData: payload => ({
    type: actionTypes.updateRecurringPaymentData,
    payload: payload,
  }),
  resetRecurringPaymentData: () => ({
    type: actionTypes.resetRecurringPaymentData,
  }),
};

export function* saga() {}
