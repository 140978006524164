export const getYearsAndMonthsDisplay = (years, months) => {
  let result = '';

  if (years) result += `${years} year${years > 1 ? 's' : ''}`;
  if (months) {
    if (result) result += ' and ';
    result += `${months} month${months > 1 ? 's' : ''}`;
  }
  if (!result.length) result = '--';

  return result;
};
