import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import './index.scss';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { useHistory } from 'react-router-dom';
import * as recurringPaymentDataState from '../_redux/recurringPaymentRedux';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const PaymentDay = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const { handleStepChange, id: stepperId } = props;

  const recurringPaymentData = useSelector(state => state.recurringPayments);

  useEffect(() => {
    handleStepChange({ next: stepperId });
  }, []);

  const [selectedDate, setSelectedDate] = useState(
    recurringPaymentData?.reminderDate
      ? recurringPaymentData?.reminderDate
      : null,
  );

  const handleNext = () => {
    dispatch(
      recurringPaymentDataState?.actions.updateRecurringPaymentData({
        ...recurringPaymentData,
        reminderDate: selectedDate,
      }),
    );
    history.push('/recurring-payments/bank-details', { previousStep: 2 });
  };

  return (
    <>
      <div className="mt-10 calendar-container border-light-blue-500 border-radius-8">
        <div className="d-flex flex-column content-center">
          <div className="calendar-icon">
            <SVG
              className="mb-1 mr-5"
              src={toAbsoluteUrl(`/media/svg/ndovu/${'new_calendar'}.svg`)}
              style={{ width: '100%', height: '72px' }}
            />
            <h6 className="calendar-text">
              What day of the month will you pay?
            </h6>
          </div>
          <div className="calendar">
            {days.map(day => (
              <div
                key={day}
                className={selectedDate === day ? 'selected' : 'day'}
                onClick={() => setSelectedDate(day)}
              >
                {day}
              </div>
            ))}
          </div>
        </div>

        <div className="w-100">
          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() => history.push('/recurring-payments')}
              twinButton
            />

            <ConfirmButton
              buttonText={'Next'}
              handleOnClick={handleNext}
              twinButton
              disable={!selectedDate}
            />
          </ButtonsContainer>
        </div>
      </div>
    </>
  );
};

export default PaymentDay;
