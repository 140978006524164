import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as goal from '../app/modules/Goal/_redux/goalReducers';
import * as profile from '../app/modules/Profile/_redux/profileRedux';
import * as wizard from '../app/modules/Wizard/_redux/wizardRedux';
import * as kyc from '../app/modules/KYC/_redux/kycRedux';
import * as device from '../app/modules/Account/_redux/devices/deviceReducers';
import * as subscription from '../app/modules/Subscriptions/_redux/subscriptionReducers';
import * as currency from '../app/modules/Account/_redux/currencies/currenciesReducers';
import * as conversion from '../app/modules/Account/_redux/conversions/conversionReducers';
import * as dashboardReducers from '../app/modules/Dashboard/_redux/dashboardReducers';
import * as plan from '../app/modules/Plan/_redux/planReducers';
import * as investment from '../app/modules/Invest/_redux/investmentReducers';
import * as pocketFlow from '../app/modules/Pocket/_redux/reducers';
import * as settlementDetailsReducer from '../app/modules/WithdrawalDetails/_redux/withdrawalReducer';
import * as businessChama from '../app/modules/Business/_redux/businessChamaReducers';
import * as suggestedGoalNames from '../app/modules/Goal/_redux/goalReducers';
import * as teamMember from '../app/modules/Management/_redux/teamMemberReducer';
import * as withdrawalReducer from '../app/modules/Portfolio/_redux/portfolioReducer';
import * as recurringPaymentData from '../app/modules/RecurringPayments/_redux/recurringPaymentRedux';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  wizard: wizard.reducer,
  profile: profile.reducer,
  riskProfileLevels: profile.updateRiskProfileLevelsReducer,
  goal: goal.reducer,
  kyc: kyc.reducer,
  devices: device.reducer,
  subscription: subscription.reducer,
  currencies: currency.reducer,
  conversions: conversion.reducer,
  plansDailyTrading: dashboardReducers.reducer,
  displayKycRejectionReason: dashboardReducers.displayKycRejectionReasonReducer,
  plan: plan.reducer,
  investment: investment.reducer,
  pocketFlow: pocketFlow.reducer,
  pockets: pocketFlow.pocketsReducer,
  pocketTransactions: pocketFlow.pocketTransactionsReducer,
  pocketDetails: pocketFlow.updatePocketReducer,
  settlementDetails: settlementDetailsReducer.settlementDetailsReducer,
  businessChama: businessChama.reducer,
  suggestedGoalNames: suggestedGoalNames.getSuggestedGoalNamesReducer,
  createdGoalsList: suggestedGoalNames.updateCreatedGoalsListReducer,
  teamMember: teamMember.reducer,
  inviteDetails: teamMember.invitesReducer,
  withdrawalDetails: withdrawalReducer.reducer,
  pocketTopupDetails: pocketFlow.updatePocketTopupDetails,
  recurringPayments: recurringPaymentData.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    wizard.saga(),
    profile.saga(),
    goal.saga(),
    kyc.saga(),
    device.saga(),
    currency.saga(),
    conversion.saga(),
    dashboardReducers.saga(),
    plan.saga(),
    pocketFlow.saga(),
    businessChama.saga(),
    suggestedGoalNames.saga(),
    teamMember.saga(),
    withdrawalReducer.saga(),
    recurringPaymentData.saga(),
  ]);
}
